import React from 'react';
import './loader.css'

export default function Loader() {
    return(
        <div id="loader" className={'spinner visible'}>
        <div className="section--white">
            <div className="container-loader">
                <div className="slice"></div>
                <div className="slice"></div>
                <div className="slice"></div>
                <div className="slice"></div>
                <div className="slice"></div>
                <div className="slice"></div>
            </div>
        </div>
    </div>
    )
}
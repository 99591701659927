import { lazy } from "react";

const Login = lazy(() => import("../Login"));
const Signup = lazy(() => import("../Signup"));
const ForgetPassword = lazy(() => import("../ForgetPassword"));
const ResetPassword = lazy(() => import("../ResetPassword"));
const LandingPage = lazy(() => import("../LandingPage"));
const OtpVerification = lazy(() => import("../OtpVerification"));
const Home = lazy(() => import("../Home"));
const Alzheimer = lazy(() => import("../Alzheimer"));
const EnhanceUserHistory = lazy(() => import("../EnhanceUserHistory"));
const Admin = lazy(() => import("../Admin"));
const EnhanceDataTable = lazy(() => import("../EnhanceDataTable"));
const Enhance = lazy(() => import("../Enhance"));
const EnhanceView = lazy(() => import("../EnhanceView"));
const AlzheimerMain = lazy(() => import("../AlzheimerMain"));
const AlzheimerView = lazy(() => import("../AlzheimerView"));
const AlzheimerUserHistory = lazy(() => import("../AlzheimerUserHistory"));
const AdminDataTable = lazy(() => import("../AdminDataTable"));
const AlzheimerDataTable = lazy(() => import("../AlzheimerDataTable"));
const ChangePassword = lazy(() => import("../ChangePassword"));
const AboutUs = lazy(() => import("../AboutUs"));
const ContactUs = lazy(() => import("../ContactUs"));
const Radiologist = lazy(() => import("../Radiologist"));
const TermsAndConditions = lazy(() => import("../Terms_and_Conditions"));

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
  { path: "/forgetpassword", component: ForgetPassword },
  { path: "/", component: LandingPage },
  { path: "/resetpassword/*", component: ResetPassword },
  { path: "/otpverify", component: OtpVerification },
  { path: "/home", component: Home, protected: true },
  { path: "/alzheimer", component: Alzheimer, protected: true },
  { path: "/enhanceuserhistory", component: EnhanceUserHistory, protected: true },
  { path: "/alzheimeruserhistory", component: AlzheimerUserHistory, protected: true },
  { path: "/admin", component: Admin, protected: true },
  { path: "/enhancedatatable", component: EnhanceDataTable, protected: true },
  { path: "/enhance", component: Enhance, protected: true },
  { path: "/enhanceview/*", component: EnhanceView, protected: true },
  { path: "/alzheimermain", component: AlzheimerMain, protected: true },
  { path: "/alzheimerview/*", component: AlzheimerView, protected: true },
  { path: "/admindatatable", component: AdminDataTable, protected: true },
  { path: "/alzheimerdatatable", component: AlzheimerDataTable, protected: true },
  { path: "/changepassword", component: ChangePassword, protected: true },
  { path: '/AboutUs', component: AboutUs, protected: false, },
  { path: '/ContactUs', component: ContactUs, protected: false, },
  { path: '/Radiologist', component: Radiologist, protected: false, },
  { path: '/Terms_and_Conditions', component: TermsAndConditions, protected: false, },
];


export default authRoutes;
